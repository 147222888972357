window.$ = window.jQuery = require('jquery');

//var oc = require('octobercms');
require("jquery-ui/ui/widget");
require("jquery-ui/ui/keycode");
require("jquery-ui/ui/widgets/mouse");
require("jquery-ui/ui/widgets/slider");

window.Popper = require('@popperjs/core').default; // default is very important.
window.bootstrap = require('bootstrap');
window.trumbowyg = require('trumbowyg');
window.WOW = require('wowjs');
require('magnific-popup');
//require('imagesloaded');
//require('jarallax');
//require('jquery.countdown');
//require('jquery.counterup');
require('jquery.easing');


import SliderPro, { Breakpoints, Fade, Caption, Fullscreen, LazyLoading, Retina, ConditionalImages, Layers, DeepLinking, Autoplay, TouchSwipe, Buttons, Keyboard, Thumbnails, ThumbnailTouchSwipe, Arrows, ThumbnailArrows, Video} from 'slider-pro-js';
SliderPro.addOns = [Breakpoints, Fade, Caption, Fullscreen, LazyLoading, Retina, ConditionalImages, Layers, DeepLinking, Autoplay, TouchSwipe, Buttons, Keyboard, Thumbnails, ThumbnailTouchSwipe, Arrows, ThumbnailArrows, Video];
window.SliderPro = SliderPro;



//window.SliderPro=require('slider-fotorama');
require('trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js');
//window.Login = new bootstrap.Modal(document.getElementById('zaloguj'), {
//    keyboard: false,
//    backdrop: "static"
//});
// require ( 'knockout' ); 
//window.multiselect=require('bootstrap-multiselect/dist/js/bootstrap-multiselect');
//window.multiselect = require('bootstrap-multiselect');
//
//require('popper.js');
//require('bootstrap');
//
//var $ = require('jquery');
//var multiselect = require('bootstrap-multiselect');
//$.multiselect = multiselect;
// 
//$("#example").multiselect();


$(() => { // Shorthand for $( document ).ready()

    "use strict";


//    const mySlider = new SliderPro( '.slider-ogloszenie', {
//			addOns: [Breakpoints,Fade,Caption,Fullscreen,LazyLoading,Retina,ConditionalImages,Layers,DeepLinking,Autoplay,TouchSwipe,Buttons,Arrows,Keyboard,Thumbnails,ThumbnailTouchSwipe,ThumbnailArrows,Video],
//			lazyloading:true,
//                        width: '100vw',
//                        autoHeight:true,                     
//                        autoplay: false,
//			fade: true,
//                        fullscreen: true,		
//                        arrows: true,
//			buttons: false,
//                        touchswipe: true,
//			thumbnailtouchswipe:true,
//			shuffle: false,
//			thumbnailArrows: false,
//			
//		
//		});

    //Form
//    require('./assets/js/trumbowyg.min.js');   



//   require('./assets/js/jakidomNiceSelectMultiple.js');
//window.multiselect=require('./assets/js/bootstrapMultiselect.js');
    require('./assets/js/jakidom.js');



//  require('./partials/form/button-change-quantity/button-change-quantity');
//  require('./partials/form/checkout-form/checkout-form-validation');
//  //Navigation
//  require('./partials/navigation/pagination/pagination');
//  //Product
//  require('./partials/product/cart-link-header/cart-link-header');
//  require('./partials/product/cart-position-list/cart-position-list');
//  require('./partials/product/catalog-product-list/catalog-product-list');
//  require('./partials/product/product-sorting/product-sorting');

//    require('./assets/js/rehomes.bundle');  
//    require('./assets/js/default-assets/active');
//    require('./node_modules/bootstrap-select/dist/js/bootstrap-select');


});
