/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

// Avoid `console` errors in browsers that lack a console.


$(window).on('ajaxConfirmMessage', function (event, message, promise) {

    if (!message) {
        return;
    }        
    event.preventDefault();  
    $('#alertConfirm').modal('show');
    $('#alertConfirmBody').text(message);




    $('#okay-button').unbind().click(function () {
        // hide modal
        $('#alertConfirm').modal('hide');
        promise.resolve();
    
    });
    $('#cancel-button').unbind().click(function () {
        // hide modal
        $('#alertConfirm').modal('hide');
        // Reject the object, this will cancel whatever was being confirmed           

        event.promise.reject();
    });
    event.preventDefault();
    // Return a value so the framework knows we're handling the confirm
    return true;
});

$('a[data-bs-toggle="tab"]').on('show.bs.tab', function (e) {
    var $target = $(e.target);
    if ($target.parent().hasClass('disabled')) {
        return false;
    }
});

$(".next-step").click(function (e) {
    var $active = $('.nav-tabs li>.active ');
    $active.parent().next().find('.nav-link').removeClass('disabled').addClass('show');
    nextTab($active);

});

$(".prev-step").click(function (e) {
    var $active = $('.nav-pills li>a.active');
    prevTab($active);
    $(window).scrollTop(0);
});



function nextTab(elem) {

    $(elem).parent().next().find('a[data-bs-toggle="tab"]').tab('show');
}
function prevTab(elem) {
    $(elem).parent().prev().find('a[data-bs-toggle="tab"]').tab('show');
}
window.nextStep = function () {
    var $active = $('.nav-pills li>.active ');
    $active.parent().next().find('.nav-link').removeClass('disabled').addClass('show');
    $active.parent().next().find('a[data-bs-toggle="tab"]').tab('show');
};
function nextStep() {
    var $active = $('.nav-pills li>.active ');
    $active.parent().next().find('.nav-link').removeClass('disabled').addClass('show');
    $active.parent().next().find('a[data-bs-toggle="tab"]').tab('show');
}


$(window).on('ajaxInvalidField', function (event, fieldElement, fieldName, errorMsg, isFirst) {

    $(fieldElement).closest('.form-group').addClass('has-error');
    $(fieldElement).addClass('is-invalid');
    $(fieldElement).siblings('.invalid-feedback').html(errorMsg);
//    event.preventDefault();
});

$(document).on('ajaxPromise', '[data-request][data-request-validate]', function () {
    $(this).closest('form').find('.form-group.has-error').removeClass('has-error');
    $(this).closest('form').find('.form-control.is-invalid').removeClass('is-invalid');
});


(function () {
    'use strict'
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {

                        event.preventDefault();
                        event.stopPropagation();

                        var errorElements = document.querySelectorAll(
                                "input.form-control:invalid");
                        errorElements.forEach(function (element) {
                            element.parentNode.childNodes.forEach(function (node) {
                                if (node.className == 'valid-feedback') {
                                    node.className = 'invalid-feedback';
                                }
                            });
                        });
                    }
                    const invalidGroup = form.querySelectorAll(":invalid");
                    for (let j = 0; j < invalidGroup.length; j++) {
                        invalidGroup[j].classList.add('is-invalid');
                    }
                    if (invalidGroup[0]) {
                        $('html, body').animate({
///                            scrollTop: $(errorElements[0]).offset().top
//                        scrollTop: parseInt($(errorElements[0] '.is-invalid').offset().top)
//                            scrollTop: parseInt($('.is-invalid').offset().top)
                            scrollTop: parseInt($(invalidGroup[0]).offset().top)
                        }, 1000);
                    }

                    $('.is-invalid').first().focus();
                }, false)
            })
})(jQuery);

(function ($) {

    var o = $({});

    $.subscribe = function () {
        o.on.apply(o, arguments);
    };

    $.unsubscribe = function () {
        o.off.apply(o, arguments);
    };

    $.publish = function () {
        o.trigger.apply(o, arguments);
    };

}(jQuery));


addEventListener('ajax:setup', function (event) {
    var context = event.detail.context;

    
    // Enable AJAX handling of Flash messages on all AJAX requests
    context.options.flash = true;

    // Handle Error Messages by triggering a flashMsg of type error
    context.options.handleErrorMessage = function (message) {
        oc.flashMsg({text: message, class: 'error'});
    }

    // Handle Flash Messages by triggering a flashMsg of the message type
    context.options.handleFlashMessage = function (message, type) {
        oc.flashMsg({text: message, class: type});
    }
});
//$.trumbowyg.svgPath = getFile("../../assets/css/fonts/icons.svg");